import Window from './components/Window';
import { ModeContext, modes } from './contexts/ModeContext';

import './App.scss';
import { useState } from 'react';

function App() {


  const [mode, setMode] = useState(modes.glass);

  function toggleDark() {
    setMode(modes.dark);
  }

  function toggleGlass() {
    setMode(modes.glass);
  }

  return (<>
    <ModeContext.Provider value={{ mode, toggleDark, toggleGlass }}>
      <Window curMode={modes.dark} />
      {/* <Window curMode={modes.glass} /> */}
    </ModeContext.Provider>
  </>
  );
}

export default App;
