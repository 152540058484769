import Code from "./Code";
import { useEffect, useState } from "react";
import { ModeContext } from "../contexts/ModeContext";

function Window({ curMode }) {
  const vals = [
    "i'm not a robot", //: 1 + 1 = 10
    "today is a good day",
    "moon in aries",
    "airbender/ravenclaw",
    "support/tank/mage",
    //"i don't c-sharp"
  ];

  const [randomTxt, setTxt] = useState(vals[0]);
  let idx = -1;

  useEffect(() => {
    const i = setInterval(() => {
      idx++;
      if (idx > 4) idx = 0;
      var newTxt = vals[idx];
      setTxt(newTxt);
    }, 3000);

    return () => clearInterval(i);
  }, []);

  return (
    <>
      <ModeContext.Consumer>
        {({ mode, toggleDark, toggleGlass }) => (
          <div
            className={
              "App " +
              (curMode === 2 ? "glass " : "") +
              ((mode === 2 && curMode === 2) || (mode === 1 && curMode === 1)
                ? "pos1 "
                : "")
            }
          >
            <header className="App-header">
              <h3>
                <code>
                  /web_dev<span className="me">/mark_figueroa</span>
                </code>
              </h3>
              {/* <div className="modes">
                            <div className="modeButtons" onClick={mode === 2 ? toggleDark : toggleGlass}></div>
                        </div> */}
            </header>
            <div>
              <Code text="javascript typescript html5 css scss"></Code>
              <Code text={<span>react angular angularjs backbone</span>}></Code>
              <Code text="c# visual basic ms-sql"></Code>

              <hr />
              <Code text="frontend development, designing or implementing designs for web ui"></Code>
              <Code text="backend api maintenance, database queries for reports"></Code>
              <Code text="enjoys learning new stuff"></Code>
              <hr />
              <Code text="english tagalog meow purr"></Code>
              <Code
                text={
                  <span>
                    i <span className="redtxt heart">&lt;3</span> coffee, cats
                    &amp; codes{" "}
                    <span className="ccc" title="i like shades of gray">
                      #ccc
                    </span>
                  </span>
                }
              ></Code>

              <hr />
              <Code
                text={
                  <span>
                    me -&gt;{" "}
                    <a
                      href="https://www.linkedin.com/in/m-figueroa"
                      target="_blank"
                    >
                      linkedin.com/in/m-figueroa{" "}
                    </a>
                  </span>
                }
              ></Code>
              <Code text="version 2024.09"></Code>
              <hr />

              <Code
                text={
                  <span>
                    {randomTxt}
                    <span className="cursor">&nbsp;_</span>
                  </span>
                }
              ></Code>
            </div>
          </div>
        )}
      </ModeContext.Consumer>
    </>
  );
}

export default Window;
